import React, { useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../Context/Context';
import API from '../../libs/ServerAPIs';
import HistoryIcon from '@mui/icons-material/History';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
} from '@mui/material';

function IdleTimerContainer() {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData, auth, activeSession, sessionTimerRef } = useData(useData);
    const [, setUserDetails] = userData;
    const [, setIsAuth] = auth;
    const [isActiveSession, setIsActiveSession] = activeSession;
    const [openModal, setOpenModal] = useState(false);
    const history = useNavigate();

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    // useEffect(() => {

    // }, [])

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function onIdle() {
        if (isActiveSession) {
            API.get('/api/v1/auth/logout').then((res) => {
                if (res.success) {
                    if (res.redirectURL) {
                        window.open(res.redirectURL);
                    }
                    setIsActiveSession(false);
                    setOpenModal(true);
                }
            });
        }
    }

    function closeModal() {
        setIsAuth(false);
        setUserDetails(null);
        localStorage.clear();
        history('/', { replace: true });
        setOpenModal(false);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <IdleTimer
                key="idleTimer"
                startOnMount={true}
                ref={sessionTimerRef}
                timeout={1000 * 60 * 30}
                onIdle={onIdle}
                debounce={500}
            />

            <Dialog
                open={openModal}
                onClose={closeModal}
                fullWidth
                disableEscapeKeyDown={true}
                disableBackdropClick
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: '320px',
                    },
                }}
            >
                <DialogContent sx={{ p: '32px' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <HistoryIcon fontSize="large" />
                    </Box>
                    <DialogContentText sx={{ textAlign: 'center' }}>
                        <Box
                            component="span"
                            sx={{
                                fontSize: '16px',
                                lineHeight: '1.4',
                                display: 'block',
                            }}
                        >
                            Your session has expired <br />
                            due to inactivity
                        </Box>
                        <Box
                            component="span"
                            sx={{ mt: '24px', display: 'block' }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={closeModal}
                            >
                                Login
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default IdleTimerContainer;
